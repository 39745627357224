import React, { useEffect, useRef } from "react"
import PropTypes from "prop-types"
import styles from "./GradientBorder.module.scss"

const GradientBorder = props => {
  const canvasWrapRef = useRef(null)
  const canvasRef = useRef(null)
  const { className, borderRadius, borderWidth, gradientFrom, color } = props

  // Wrapper class
  const wrapperClasses = [styles.canvasWrap]
  if (className) {
    wrapperClasses.push(props.className)
  }

  // Border radius
  const borders = []
  if (borderRadius && borderRadius.length) {
    switch (borderRadius.length) {
      case 1:
        borders.push(
          borderRadius[0],
          borderRadius[0],
          borderRadius[0],
          borderRadius[0]
        )
        break
      case 2:
        borders.push(
          borderRadius[0],
          borderRadius[1],
          borderRadius[0],
          borderRadius[1]
        )
        break
      case 3:
        borders.push(
          borderRadius[0],
          borderRadius[1],
          borderRadius[2],
          borderRadius[1]
        )
        break
      case 4:
        borders.push(...borderRadius)
        break
      default:
        borders.push(40, 40, 40, 40)
    }
  } else {
    borders.push(40, 40, 40, 40)
  }

  // Border width
  const lineWidth = borderWidth

  // Get gradient line points to draw gradient (from dark to light)
  const getGradientLinePoints = ref => {
    const gradientPoints = []
    switch (gradientFrom) {
      case "top":
        gradientPoints.push(0, 0, 0, ref.current.offsetHeight)
        break
      case "right":
        gradientPoints.push(ref.current.offsetWidth, 0, 0, 0)
        break
      case "bottom":
        gradientPoints.push(0, ref.current.offsetHeight, 0, 0)
        break
      case "left":
      default:
        gradientPoints.push(0, 0, ref.current.offsetWidth, 0)
        break
    }

    return gradientPoints
  }

  // Draw border
  const drawBorder = () => {
    const ctx = canvasRef.current.getContext("2d")

    const gradientLinePoints = getGradientLinePoints(canvasRef)
    const grad = ctx.createLinearGradient(...gradientLinePoints)
    grad.addColorStop(0, color === "blue" ? "#316df0" : "#060d34")
    grad.addColorStop(1, "#316df0")

    ctx.strokeStyle = grad
    ctx.lineWidth = lineWidth

    if (typeof ctx.roundRect === "function") {
      ctx.beginPath()
      ctx.roundRect(
        lineWidth / 2,
        lineWidth / 2,
        canvasRef.current.offsetWidth - lineWidth,
        canvasRef.current.offsetHeight - lineWidth,
        borders
      )
      ctx.stroke()
    } else {
      drawRoundRect(
        ctx,
        lineWidth / 2,
        lineWidth / 2,
        canvasRef.current.offsetWidth - lineWidth,
        canvasRef.current.offsetHeight - lineWidth,
        borders
      )
    }
  }

  // Custom implementation to draw rounded rectangle on canvas
  // ctx.roundRect is not supported in firefox
  const drawRoundRect = function (ctx, x, y, width, height, radius) {
    const smallDimension = Math.min(width, height)
    const newRad = radius.map(rad => {
      if (smallDimension < 2 * rad) {
        return Math.abs(smallDimension / 2)
      }
      return Math.abs(rad)
    })

    ctx.beginPath()
    ctx.moveTo(x + newRad[0], y)
    ctx.arcTo(x + width, y, x + width, y + height, newRad[1])
    ctx.arcTo(x + width, y + height, x, y + height, newRad[2])
    ctx.arcTo(x, y + height, x, y, newRad[3])
    ctx.arcTo(x, y, x + width, y, newRad[0])
    ctx.stroke()
  }

  const onResize = (width, height) => {
    canvasRef.current.setAttribute("width", `${width}px`)
    canvasRef.current.setAttribute("height", `${height}px`)
    drawBorder()
  }

  useEffect(() => {
    const resizeObserver = new ResizeObserver(entries => {
      const size = entries[0].contentRect
      onResize(Math.floor(size.width), Math.floor(size.height))
    })

    resizeObserver.observe(canvasWrapRef.current)

    return () => {
      if (resizeObserver) {
        resizeObserver.disconnect()
      }
    }
  }, [])

  return (
    <div ref={canvasWrapRef} className={wrapperClasses.join(" ")}>
      <canvas ref={canvasRef} width="100" height="100"></canvas>
    </div>
  )
}

GradientBorder.defaultProps = {
  className: "",
  borderWidth: 2,
  gradientFrom: "left", // from dark to light color
  borderRadius: [40, 40, 40, 40]
}
GradientBorder.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  borderWidth: PropTypes.number,
  gradientFrom: PropTypes.string,
  borderRadius: PropTypes.arrayOf(PropTypes.number)
}
export default GradientBorder
