import React, { Component } from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import PropType from "prop-types"

import styles from "./NotFound.module.scss"
import Link from "../Link/Link"
import GradientBorder from "../GradientBorder/GradientBorder"
import { Container } from "react-bootstrap"
import MarkdownTitle from "../MarkdownTitle"

export default class NotFound extends Component {
  render() {
    return (
      <>
        {this.props.type !== "new" ? (
          <div className={styles.notFound}>
            {this.props.image && (
              <GatsbyImage image={this.props.image.gatsbyImageData} alt="" />
            )}
            <h1 className={[styles.title, "h2"].join(" ")}>
              <MarkdownTitle text={this.props.title} />
            </h1>
            <p>{this.props.message}</p>
            <a href={this.props.ctaURL}>{this.props.ctaTitle}</a>
          </div>
        ) : (
          <Container
            fluid
            className={styles.notFoundNew}
            style={{
              backgroundImage: `url(${this.props.image.gatsbyImageData.images.fallback.src})`
            }}
          >
            <div className={styles.notFoundWrapper}>
              <div className={styles.notFoundBox}>
                <div className={styles.borderWrap}>
                  <GradientBorder
                    borderRadius={[0, 32, 32, 32]}
                    color="blue"
                    gradientFrom="left"
                  />
                </div>
                {this.props.image404 && (
                  <GatsbyImage
                    image={this.props.image404.gatsbyImageData}
                    alt="404"
                    style={{ marginBottom: "48px", width: "52%" }}
                  />
                )}
                <h1 className={[styles.title, "h2"].join(" ")}>
                  {this.props.titleA}
                </h1>
                <h1 className={[styles.titleB, "h2"].join(" ")}>
                  {this.props.titleB}
                </h1>
                <p>{this.props.message}</p>
                <div className={styles.linkWrapper}>
                  {this.renderButton(this.props.linkHome)}
                </div>
              </div>
            </div>
          </Container>
        )}
      </>
    )
  }

  renderButton(link) {
    const defaultLocale = this.props.defaultLocale
    const props = {
      link: { ...link },
      locale: this.props.locale,
      defaultLocale
    }

    return (
      <>
        <div className={styles.link_button}>
          <Link {...props} />
        </div>
      </>
    )
  }
}

NotFound.propTypes = {
  image: PropType.object,
  image404: PropType.object,
  title: PropType.string,
  titleA: PropType.string.isRequired,
  titleB: PropType.string.isRequired,
  message: PropType.string,
  ctaTitle: PropType.string,
  ctaURL: PropType.string,
  type: PropType.string,
  locale: PropType.string,
  defaultLocale: PropType.string,
  linkDemo: PropType.object,
  linkHome: PropType.object
}
